<template>
    <div class="row contact__person">
        <div class="col col__image">
            <img :src="person.image" />
        </div>
        <div class="col col__info">
            <h3>{{ person.name }}</h3>
            <ul>
                <li class="position" v-html="person.position"></li>
                <li class="mail">
                    <a :href="'mailto:' + (showMail ? person.email : '#')" @mouseenter="showMail = true">
                        <span class="icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="31.25"
                                height="31.25"
                                viewBox="0 0 31.25 31.25"
                            >
                                <circle cx="15.625" cy="15.625" r="15" />
                                <rect x="8.125" y="10.17" width="15.341" height="10.909" />
                                <polyline points="8.125 11.534 15.807 17.67 23.466 11.534" />
                                <line x1="8.125" y1="20.856" x2="13.199" y2="15.813" />
                                <line x1="23.363" y1="20.79" x2="18.288" y2="15.747" />
                            </svg>
                        </span>
                        <span
                            class="label"
                            v-html="
                                person.email.replace(
                                    '@',
                                    `<span style=&quot;display: none&quot;>No Spam Please</span>@`
                                )
                            "
                        >
                        </span>
                    </a>
                </li>
                <li class="phone">
                    <a :href="'tel:' + (showPhone ? person.phone : '#')" @mouseenter="showPhone = true">
                        <span class="icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="31.25"
                                height="31.25"
                                viewBox="0 0 31.25 31.25"
                            >
                                <circle cx="15.625" cy="15.625" r="15" />
                                <path
                                    d="M19.585,23.466a5.835,5.835,0,0,1-2.315-.538,12.272,12.272,0,0,1-4.877-4.192l-.21-.27C11.089,17.06,8.727,13.4,10.426,9.775c.047-.083.914-1.531,1.959-1.643.131-.022.656-.033,1.268.7.465.559,1.65,2.376,1.361,3.283a.277.277,0,0,1-.041.078c-.006.007-.483.62-.724,1.013a2.9,2.9,0,0,1-.2.277,1.529,1.529,0,0,0-.37,1.63c.351,1.093,1.981,3.971,3.574,3.971a2.406,2.406,0,0,0,1.2-.462.283.283,0,0,1,.028-.02,1.31,1.31,0,0,1,.634-.153,1.575,1.575,0,0,1,.8.229c.537.315,3.116,2.171,2.411,3.386a3.068,3.068,0,0,1-2.737,1.4"
                                />
                            </svg>
                        </span>
                        <span class="label">{{ person.phone }}</span>
                    </a>
                </li>
            </ul>
            <button class="contact__button mperson__item-buttom" @click="toggleForm">
                <span> {{ lll['tx_contactpersons_domain_model_service.contactbutton'] }}</span>
            </button>
        </div>
    </div>
</template>

<script>
let personid = null
const $formInputs = document.getElementsByTagName('input')
const $contactVueSection = document.getElementsByClassName('ce-contactfinder')

export default {
    name: 'Person',
    props: {
        person: Object,
        lll: Object,
    },
    data() {
        return {
            showMail: false,
            showPhone: false,
        }
    },
    mounted() {
        for (const input of $formInputs) {
            if (input.id.includes('personid')) {
                personid = input
                personid.value = this.person['uid']
            }
        }
    },
    methods: {
        toggleForm() {
            if ($contactVueSection[0]) {
                $contactVueSection[0].classList.toggle('form-hidden')
            }
        },
    },
}
</script>

<style scoped lang="scss"></style>
