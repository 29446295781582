<template>
    <label :for="selectType">{{ $placeholder ? $placeholder : title }}</label> <br />
    <select
        @change=";[selection($event.target.value, selectType), selectAgent($event.target.value)]"
        :name="selectType"
        :id="selectType"
        :disabled="preselect"
    >
        <option selected disabled>{{ placeholder }}</option>
        <option
            :selected="option.uid === preselect"
            v-for="option of selectObj[selectType]"
            :key="option.uid"
            :value="JSON.stringify(option)"
        >
            {{ option.title }}
        </option>
    </select>
    <br />
</template>

<script>
export default {
    name: 'SelectField',
    data() {
        return {
            $placeholder: null,
        }
    },
    props: {
        selection: Function,
        title: String,
        selectObj: Object,
        selectType: String,
        placeholder: String,
        preselect: Number,
    },
    mounted() {
        if (this.preselect) {
            const preSelectedObj = this.selectObj[this.selectType].filter((a) => {
                return this.preselect === a.uid
            })
            this.$placeholder = preSelectedObj[0].title
            this.selection(JSON.stringify(preSelectedObj[0]), this.selectType)
        }
    },
    methods: {
        selectAgent(e) {
            e = JSON.parse(e)
            this.$placeholder = e.title
        },
    },
}
</script>

<style scoped></style>
