<template>
    <SelectField
        v-if="services"
        :selection="selectAction"
        :select-obj="services"
        :title="lll['tx_contactpersons_domain_model_service.interest']"
        select-type="services"
        :placeholder="lll['tx_contactpersons_domain_model_service.pleaseSelect']"
        :preselect="servicesPreselection"
    />
    <SelectField
        v-if="user.services"
        :selection="selectAction"
        :select-obj="user.services"
        :title="user.services.section"
        select-type="themes"
        :placeholder="lll['tx_contactpersons_domain_model_service.pleaseSelect']"
        :preselect="themesPreselection"
    />
    <SelectField
        v-if="user.themes && user.themes.hasOwnProperty('interest')"
        :selection="selectAction"
        :select-obj="user.themes"
        :title="lll['tx_contactpersons_domain_model_service.area']"
        select-type="interest"
        :placeholder="lll['tx_contactpersons_domain_model_service.pleaseSelect']"
    />
</template>

<script>
let $dataAttrPreselection = document.getElementById('df-contact-vue')
$dataAttrPreselection ? ($dataAttrPreselection = $dataAttrPreselection.dataset) : null

import SelectField from '@/components/SelectField'

export default {
    name: 'ContactFinder',
    components: { SelectField },
    data() {
        return {
            services: null,
            servicesPreselection: null,
            themesPreselection: null,
            user: {
                services: null,
                themes: null,
                interest: null,
            },
        }
    },
    props: {
        selectedService: Function,
        lll: Object,
    },
    mounted() {
        if ($dataAttrPreselection.services) {
            this.servicesPreselection = parseInt($dataAttrPreselection.services)
            // console.log("userSelections", this.user);
        }
        if ($dataAttrPreselection.themes) {
            this.themesPreselection = parseInt($dataAttrPreselection.themes)
            // console.log("userSelections", this.user);
        }
        this.fetchApiData(
            `${process.env.VUE_APP_URL_BASE}${document.documentElement.lang.substring(0, 2)}${
                process.env.VUE_APP_URL_SERVICE
            }`
        ).then((d) => {
            this.services = d
            // console.log("fetchApiData services", this.services);
        })
    },
    methods: {
        async fetchApiData(url) {
            return fetch(url)
                .then((res) => res.json())
                .then((data) => {
                    return data
                })
                .catch((err) => console.log(err))
        },
        selectAction(selection, type) {
            if (type === 'services') {
                this.user.themes = null
                this.user.interest = null
            }
            if (type === 'themes') {
                this.user.interest = null
            }
            selection = JSON.parse(selection)
            this.user[type] = selection
            this.checkIfReady(this.user)
        },
        checkIfReady(userslection) {
            let continueQuestions = true
            let serviceID = null
            if (userslection.services) {
                // console.log("checkIfReady", "services found");
                if (userslection.themes) {
                    // console.log("checkIfReady", "themes found");
                    // eslint-disable-next-line no-prototype-builtins
                    if (userslection.themes.hasOwnProperty('interest')) {
                        // console.log("checkIfReady", "interest found");
                        if (userslection.interest) {
                            serviceID = userslection.interest.uid
                            continueQuestions = false
                        }
                    } else {
                        serviceID = userslection.themes.uid
                        continueQuestions = false
                    }
                }
            }
            // console.log("continueQuestions", continueQuestions);
            if (!continueQuestions) {
                // console.log(this.user);
                this.selectedService(serviceID)
            }
        },
    },
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped></style>
