<template>
  <div class="container">
    <div class="h1" v-if="lll && preSelectedService === null">
      {{ lll['tx_contactpersons_domain_model_service.findContact'] }}
    </div>
    <div class="h1 h1-vue" v-if="preSelectedService !== null && lll">
      <span v-if="!text.headline">{{ lll['tx_contactpersons_domain_model_contactperson'] }}</span>
      <span v-if="text.headline">{{ text.headline }}</span>
    </div>
    <p class="subline" v-if="text.text" v-html="text.text"></p>
    <p class="subline" v-if="!text.text && lll">{{lll['tx_contactpersons_domain_model_service.text_default']}}</p>
    <div v-if="countries">
      <h2>{{ lll['tx_contactpersons_domain_model_service.inCountry'] }}</h2>
      <Multiselect
          @select="countrySelection($event, true)"
          v-model="selectedCountry"
          :options="countries"
          :searchable="true"
      />
    </div>

    <template v-if="preSelectedService === null && selectedCountry && lll">
      <ContactFinder :selected-service="setService" :lll="lll" />
    </template>
    <template v-if="preSelectedService !== null">
      <template v-for="person in preSelectedPersons" :key="person">
        <Person :person="person" :lll="lll" />
      </template>
    </template>
    <!-- <button @click="changeContact">change Contact</button> -->
  </div>
</template>

<script>
let $dataAttrPreselection = document.getElementById('df-contact-vue')
$dataAttrPreselection ? ($dataAttrPreselection = $dataAttrPreselection.dataset) : null
import jsonata from 'jsonata'
import Person from '@/components/Person'
import ContactFinder from '@/components/ContactFinder'

import Multiselect from '@vueform/multiselect'

export default {
  name: 'Main',
  components: {ContactFinder, Person, Multiselect},
  data() {
    return {
      countries: null,
      selectedCountry: null,
      countryApi: null,
      countryApiUrl: 'https://pro.ip-api.com/json?key=nQZiNTI36tK45CS',
      persons: [],
      test: [],
      preSelectedPersons: [],
      preSelectedService: null, // 17 Recycling
      lll: null,
      changeable: false,
      langUrl: 'de',
      text: {
        headline: null,
        text: null,
      },
    }
  },
  mounted() {
    this.langUrl = $dataAttrPreselection['country'] === 'US' ? 'en' : 'de'
    this.changeable = $dataAttrPreselection['changeable'] === '1'
    this.fetchApiData(`${process.env.VUE_APP_URL_BASE}${this.langUrl}${process.env.VUE_APP_URL_LANGUAGE}`).then(
        (d) => {
          this.lll = d
          // console.log("fetchApiData lll", this.lll);
        }
    )
    if ($dataAttrPreselection['directcontact']) {
      this.preSelectedService = parseInt($dataAttrPreselection['directcontact'])
      this.personFetcher()
    } else {
      this.personFetcher()
    }
    console.log($dataAttrPreselection)
    this.text.text = $dataAttrPreselection['text']
    this.text.text = JSON.parse(this.text.text)
    this.text.headline = $dataAttrPreselection['headline']
  },
  methods: {
    personFetcher() {
      if (this.selectedCountry) {
        this.fetchPersonByService(this.preSelectedService, this.selectedCountry)
      } else {
        this.fetchApiData(this.countryApiUrl)
            .then((c) => {
              this.countryApi = c
              this.selectedCountry = this.countryApi['countryCode']
              this.fetchPersonByService(this.preSelectedService, this.countryApi['countryCode'])
            })
            .catch(() => {
              if ($dataAttrPreselection['country']) {
                this.fetchPersonByService(this.preSelectedService, $dataAttrPreselection['country'])
              } else {
                this.fetchApiData(
                    `${process.env.VUE_APP_URL_BASE}${document.documentElement.lang}${process.env.VUE_APP_URL_COUNTRIES}`
                ).then((d) => {
                  console.log(d)
                  this.countries = d.countries
                  this.countries = jsonata("$.{'value':isoCodeA2, 'label': name}").evaluate(
                      this.countries
                  )
                })
              }
            })
      }
    },
    fetchPersonByService(id, country) {
      this.fetchApiData(`${process.env.VUE_APP_URL_BASE}de${process.env.VUE_APP_URL_PERSONS}`).then((a) => {
        this.persons = a.persons
        if (country) {
          this.servicesFilter(this.persons, 'services', String(id)).then((p) => {
            let filteredItems = []
            filteredItems = [
              ...p.filter((a) => {
                return Object.values(a['countries']).includes(country)
              }),
            ]
            this.preSelectedPersons = filteredItems
          })
        } else {
          this.servicesFilter(this.persons, 'services', String(id)).then((p) => {
            this.preSelectedPersons = p
          })
        }
      })
    },
    changeContact() {
      this.preSelectedPersons = []
      this.preSelectedService = null
      this.fetchApiData(
          `${process.env.VUE_APP_URL_BASE}${document.documentElement.lang}${process.env.VUE_APP_URL_COUNTRIES}`
      ).then((d) => {
        this.countries = d.countries
        this.countries = jsonata("$.{'value':isoCodeA2, 'label': name}").evaluate(this.countries)
      })
    },
    countrySelection(country, filter) {
      if (filter) {
        this.fetchPersonByService(this.preSelectedService, country)
      }
    },
    setService(s) {
      // console.log("setService", s);
      this.preSelectedService = s
      if (s) {
        this.personFetcher()
      }
    },
    async servicesFilter(array, key, compare) {
      let filteredItems = []
      filteredItems = [
        ...array.filter((a) => {
          return Object.keys(a[key]).includes(compare)
        }),
      ]
      return filteredItems
    },
    async fetchApiData(url) {
      return fetch(url)
          .then((res) => res.json())
          .then((data) => {
            return data
          })
    },
    logENV() {
      console.log(process.env.VUE_APP_URL_BASE)
      console.log(process.env.VUE_APP_URL_SERVICE)
      console.log(process.env.VUE_APP_URL_PERSONS)
      console.log(process.env.VUE_APP_URL_COUNTRIES_BY_CONTINENT)
      console.log(process.env.VUE_APP_URL_LANGUAGE)
      console.log(process.env.VUE_APP_URL_COUNTRIES)
    },
  },
}
</script>

<style scoped>
div.h1.h1-vue{
  margin-bottom: 20px;
}
p.subline{
  margin-bottom: 75px;
  text-align: center;
  font-size: 19px;
}
</style>
