<template>
  <section class="prod-finder">
    <div class="no-container">
      <div class="pf__main">
        <div class="pf__wrap mt-no">
          <div class="pf__filter">
            <div class="h3">{{ lll['tx_contactpersons_domain_model_service.filterheader'] }}</div>
            <AsyncFilterBlock
                v-if="services && lll"
                :filterObj="this.services"
                select-type="services"
                :title="lll['tx_contactpersons_domain_model_service.interest']"
                :placeholder="lll['tx_contactpersons_domain_model_service.pleaseSelect']"
                :set-selection="selectAction"
            />
            <AsyncFilterBlock
                v-if="user.services && lll"
                :filterObj="user.services.themes"
                select-type="themes"
                :title="user.services.section"
                :placeholder="lll['tx_contactpersons_domain_model_service.pleaseSelect']"
                :set-selection="selectAction"
            />
            <AsyncFilterBlock
                v-if="user.themes && user.themes.hasOwnProperty('interest')"
                :filterObj="user.themes.interest"
                select-type="interest"
                :title="lll['tx_contactpersons_domain_model_service.area']"
                :placeholder="lll['tx_contactpersons_domain_model_service.pleaseSelect']"
                :set-selection="selectAction"
            />
            <div class="pf__dropdown" v-if="countryListReady && lll">
              <div class="pf__dropdown-top lang">
                <span>{{ lll['tx_contactpersons_domain_model_service.inCountry'] }}</span>
                <span class="clean-pr-alt" v-if="!countryListSelected">
                  {{ lll['tx_contactpersons_domain_model_service.pleaseSelect'] }}
                </span>
                <span class="clean-pr-alt" v-if="countryListSelected">
                </span>
              </div>
              <div class="pf__dropdown-details">
                <v-select
                    v-if="countryListReady"
                    :value="true"
                    :options="countryList"
                    :reduce="(country) => country.code"
                    v-model="countryListSelected"
                    :placeholder="lll['tx_contactpersons_domain_model_service.pleaseSelect']"
                ></v-select>
              </div>
            </div>
            <button v-if="this.user.services || this.user.interest || this.user.themes" class="btn-reset" @click="reloadPage()">{{lll['tx_contactpersons_domain_model_service.reset']}}</button>
          </div>
          <div class="pf__result" v-if="filterUsed && lll">
            <div class="pf__result-inner">
              <div class="pf__card-wrap">
                <AsyncContactPerson :lll="lll" v-for="person of persons" :key="person" :person="person" :showButton="showButton" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import vSelect from 'vue-select'
import {defineAsyncComponent} from 'vue'
import 'vue-select/dist/vue-select.css'

const AsyncContactPerson = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "ContactPerson"*/ './ContactPerson'),
  delay: 200,
  timeout: 3000,
})

const AsyncFilterBlock = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: "FilterBlock"*/ './FilterBlock'),
  delay: 500,
  timeout: 3000,
})

let $dataAttrPreselection = document.getElementById('df-contact-vue')
$dataAttrPreselection ? ($dataAttrPreselection = $dataAttrPreselection.dataset) : null

export default {
  name: 'AllContacts',
  components: {AsyncContactPerson, AsyncFilterBlock, vSelect},
  data() {
    return {
      countries: null,
      countryList: null,
      countryListReady: false,
      countryListSelected: null,
      selectedCountry: null,
      countryApi: null,
      countryApiUrl: 'https://pro.ip-api.com/json?key=nQZiNTI36tK45CS',
      personsRaw: [],
      personsUnfiltered: [],
      persons: [],
      personsForCountryFilter: [],
      lll: null,
      changeable: false,
      langUrl: 'en',
      services: null,
      debugcountry: false,
      debugValue: null,
      filterUsed: false,
      showButton: false,
      user: {
        services: null,
        themes: null,
        interest: null,
      },
    }
  },
  watch: {
    countryListSelected(newValue) {
      if (newValue) {
        this.persons = [
          ...this.personsForCountryFilter.filter((a) => {
            return Object.values(a['countries']).includes(this.countryListSelected)
          }),
        ]
      } else {
        this.persons = [...this.personsForCountryFilter]
        this.countryListSelected = null
      }
    },
    persons() {
      if (this.persons.length === 1) {
        console.log('Only one person', this.persons[0])
        document.querySelector('.ce-contactfinder--listall form input[name*="personid"]').value =
            this.persons[0].uid
        document.querySelector('#contactpersonEN-6749 #statictext-3') ? document.querySelector('#contactpersonEN-6749 #statictext-3').style.display = 'none' : null
        document.querySelector('#contactpersonEN-6749 #statictext-3') ? document.querySelector('#contactpersonEN-6749 #statictext-2').style.display = 'block' : null
        document.querySelector('#contactperson-6731 #statictext-3') ? document.querySelector('#contactperson-6731 #statictext-3').style.display = 'none' : null
        document.querySelector('#contactperson-6731 #statictext-3') ? document.querySelector('#contactperson-6731 #statictext-2').style.display = 'block' : null
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
  },
  mounted() {
    window.addEventListener('load', () => {
      document.querySelector('#contactpersonEN-6749 #statictext-3') ? document.querySelector('#contactpersonEN-6749 #statictext-3').style.display = 'block' : null
      document.querySelector('#contactpersonEN-6749 #statictext-3') ? document.querySelector('#contactpersonEN-6749 #statictext-2').style.display = 'none' : null
      document.querySelector('#contactperson-6731 #statictext-3') ? document.querySelector('#contactperson-6731 #statictext-3').style.display = 'block' : null
      document.querySelector('#contactperson-6731 #statictext-3') ? document.querySelector('#contactperson-6731 #statictext-2').style.display = 'none' : null
    })
    if (document.documentElement.lang === 'de') {
      this.langUrl = 'de'
    }
    if (document.documentElement.lang === 'en-US') {
      this.langUrl = 'en'
    }
    this.fetchApiData(`${process.env.VUE_APP_URL_BASE}${this.langUrl}${process.env.VUE_APP_URL_PERSONS}`).then(
        (a) => {
          this.persons = a.persons
          this.personsUnfiltered = a.persons
          this.personsForCountryFilter = a.persons
          this.personsRaw = a.persons
          console.log('fetchApiData persons', this.persons)
          this.fetchApiData(this.countryApiUrl).then((c) => {
            this.countryApi = c
            this.selectedCountry = this.countryApi['countryCode']
            console.log('Filter by Country: ' + this.selectedCountry)
          })
        }
    )
    this.fetchApiData(`${process.env.VUE_APP_URL_BASE}${this.langUrl}${process.env.VUE_APP_URL_LANGUAGE}`).then(
        (d) => {
          this.lll = d
          console.log('fetchApiData lll', this.lll)
        }
    )
    this.fetchApiData(`${process.env.VUE_APP_URL_BASE}${this.langUrl}${process.env.VUE_APP_URL_SERVICE}`).then(
        (s) => {
          this.services = s['services']
          console.log('fetchApiData services', this.services)
        }
    )
    this.fetchApiData(`${process.env.VUE_APP_URL_BASE}${this.langUrl}${process.env.VUE_APP_URL_COUNTRIES}`).then(
        (c) => {
          c['countries'].map((a) => {
            a['label'] = a['name']
            a['code'] = a['isoCodeA2']
          })
          this.countryList = c['countries']
          console.log(typeof c['countries'])
          console.log('fetchApiData countries', this.countryList)
          setTimeout(() => {
            this.countryListReady = true
          }, 250)
        }
    )
    this.$emit('open')
  },
  methods: {
    reloadPage() {
      window.location.reload()
    },
    async fetchApiData(url) {
      return fetch(url)
          .then((res) => res.json())
          .then((data) => {
            return data
          })
    },
    getSelection(selection) {
      console.log(selection)
    },
    selectAction(selection, type) {
      document.querySelector('.ce-contactfinder--listall')?.classList.add('ce-contactfinder--active')
      document.querySelector('.ce-contactfinder--listall form')?.classList.add('hidden')
      this.filterUsed = true
      if (type === 'services') {
        this.user.themes = null
        this.user.interest = null
      }
      if (type === 'themes') {
        this.user.interest = null
      }
      selection = JSON.parse(selection)
      this.user[type] = selection
      this.servicesFilter(this.personsUnfiltered, 'services', this.user[type].uid.toString()).then((p) => {
        this.persons = p
        this.personsForCountryFilter = p
        console.log('persons', this.persons)
        console.log('personsForCountryFilter', this.personsForCountryFilter)
        if (this.countryListSelected) {
          console.log('countryListSelected', this.countryListSelected)
          this.persons = [
            ...this.persons.filter((a) => {
              return Object.values(a['countries']).includes(this.countryListSelected)
            }),
          ]
        } else if (this.selectedCountry) {
          console.log('selectedCountry', this.selectedCountry)
          this.persons = [
            ...this.persons.filter((a) => {
              return Object.values(a['countries']).includes(this.selectedCountry)
            }),
          ]
        }
      })
    },
    async servicesFilter(array, key, compare) {
      let filteredItems = []
      filteredItems = [
        ...array.filter((a) => {
          return Object.keys(a[key]).includes(compare)
        }),
      ]
      return filteredItems
    },
  },
}
</script>

<style lang="scss" scoped>

.pf__wrap {
  flex-direction: row-reverse;
}

.pf__filter {
  @media (min-width: 1200px) {
    margin-left: 40px;
  }
}

.pf__result {

}

// max screen width of 768px
@media (max-width: 768px) {
  .prod-finder, .pf__wrap {
    margin-top: 0;
  }
}

</style>
<style lang="scss">
.vs__dropdown-toggle {
  border: none !important;
}

.vs__dropdown-menu {
  background-color: #ececec;
}

#df-contact-vue {
  .pf__dropdown-top:before {
    display: none !important;
  }
}

// min screedn width of 992px
@media (min-width: 992px) {
  .ce-contactfinder.ce-contactfinder--listall > .container:first-child {
    display: flex;
    flex-direction: row-reverse;

    #df-contact-vue {
      flex: 1 0 40%;
    }

    form {
      flex: 1 0 calc(60% - 40px);
      margin-right: 40px;
      box-sizing: border-box;
    }
  }
}

.ce-contactfinder.ce-contactfinder--listall.ce-contactfinder--active > .container:first-child {
  flex-direction: column;

  #df-contact-vue {
    flex: 1 0 100%;
  }

  form {
    flex: 1 0 100%;
  }
}

.ce-contactfinder--listall form.hidden {
  display: none;
}

.ce-contactfinder--listall .pf__dropdown-list li.active i {
  display: none;
}
.btn-reset{
  box-sizing: border-box;
  margin: 0;
  display: block;
  text-align: center;
  vertical-align: middle;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  flex: 1 1 auto;
  width: 100%;
  text-transform: uppercase;
  position: relative;
  border: none !important;
  background-color: #005da5;
  color: white !important;
}
</style>
