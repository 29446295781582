<template>
  <Main v-if="!renderAll" />
  <AllContacts v-if="renderAll" />
</template>

<script>
import Main from '@/components/Main'
import AllContacts from '@/components_all/AllContacts'

let $dataAttr = document.getElementById('df-contact-vue')
$dataAttr ? ($dataAttr = $dataAttr.dataset) : null

export default {
  name: 'DFContact',
  components: {
    AllContacts,
    Main,
  },
  data() {
    return {
      renderAll: false,
    }
  },
  mounted() {
    $dataAttr['listall'] === 'true' ? (this.renderAll = true) : (this.renderAll = false)
  },
}
</script>

<style lang="scss">
@import './main.scss';
</style>
